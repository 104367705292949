<template>
  <div>
    <el-card class="mb15" shadow="hover">
      <el-row>
        <el-input
          :placeholder="$t('search')"
          clearable
          v-model="filter.search"
          @keyup.enter.native="get_list"
        ></el-input>
      </el-row>
      <el-row class="mt20" type="flex" align="middle">
        <el-button @click="get_list" type="primary" class="search">{{
          $t("search_zh")
        }}</el-button>
        <el-button @click="reset" type="info">{{
          $t("btnTip.reset")
        }}</el-button>
      </el-row>
    </el-card>
    <el-card shadow="hover">
      <div class="flexBC mb20">
        <el-button type="primary" @click="handleClick('add')">{{
          $t("btnTip.add")
        }}</el-button>
        <el-button type="primary" @click="outExe">{{ $t("outExe") }}</el-button>
      </div>
      <my-table
        :columns="columns"
        :data="list_data.list"
        :showIndex="false"
        :showSelection="false"
        :cellStyle="{ padding: '6px 0' }"
        :headerCellStyle="{
          background: 'rgba(51, 55, 68, 1)',
          color: '#fff',
        }"
        @emitSelection="allSelect"
        @sortChange="sort_change"
      >
      <template v-slot:row1="slotProps">
          <div :class="{'fwB' : slotProps.callback.row.content[0].size === 1}">
            {{ slotProps.callback.row.content[0].row }}
          </div>
        </template>
        <template v-slot:row2="slotProps">
          <div :class="{'fwB' : slotProps.callback.row.content[1].size === 1}">
            {{ slotProps.callback.row.content[1].row }}
          </div>
        </template>
        <template v-slot:row3="slotProps">
          <div :class="{'fwB' : slotProps.callback.row.content[2].size === 1}">
            {{ slotProps.callback.row.content[2].row }}
          </div>
        </template>
        <template v-slot:status="slotProps">
          <el-tag :type="statusTag(slotProps.callback.row.status)">
            {{ status(slotProps.callback.row.status) }}
          </el-tag>
        </template>
        <template v-slot:operation="slotProps">
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('btnTip').check"
            placement="top"
          >
            <el-button
              @click="handleClick('check', slotProps.callback.row)"
              icon="el-icon-view"
              type="success"
              size="mini"
            ></el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('btnTip').edit"
            placement="top"
          >
            <el-button
              @click="handleClick('edit', slotProps.callback.row)"
              icon="el-icon-edit-outline"
              :type="'primary'"
              size="mini"
            ></el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            :content="
              slotProps.callback.row.status === 0
                ? $t('pass.status1')
                : $t('pass.status0')
            "
            placement="top"
          >
            <el-button
              @click="handleClick('status', slotProps.callback.row)"
              :type="
                slotProps.callback.row.status === 0 ? 'success' : 'warning'
              "
              size="mini"
              >{{
                slotProps.callback.row.status === 0
                  ? $t("pass.status1")
                  : $t("pass.status0")
              }}</el-button
            >
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('btnTip').delete"
            placement="top"
          >
            <el-button
              @click="del(slotProps.callback.row.id)"
              type="danger"
              size="mini"
              >{{ $t("btnTip").delete }}</el-button
            >
          </el-tooltip>
        </template>
      </my-table>
      <page
        :total="list_data.total"
        :page_size.sync="pageSize"
        :page.sync="page"
      />
    </el-card>
  </div>
</template>
<script>
import { mixin_list } from "@/mixins";
import page from "@/components/page";
import myTable from "@/components/table";
import { passList, passEdit, passDelete } from "@/api/index";
export default {
  name: "pass",
  mixins: [mixin_list(passList)],
  components: {
    myTable,
    page,
  },
  data() {
    return {
      filter: {
        search: "",
      },
      columns: [
        {
          label: "ID",
          sortable: true,
          prop: "id",
          width: 80,
        },
        {
          label: this.$t("pass.create_time"),
          sortable: true,
          prop: "create_time",
        },
        {
          label: this.$t("pass.username"),
          sortable: true,
          prop: "username",
        },
        {
          label: this.$t("pass.subject"),
          sortable: true,
          prop: "subject",
        },
        {
          label: this.$t("pass.row1"),
          prop: "content[0].row",
          slot: "row1"
        },
        {
          label: this.$t("pass.row2"),
          prop: "content[1].row",
          slot: "row2"
        },
        {
          label: this.$t("pass.row3"),
          prop: "content[2].row",
          slot: "row3"
        },
        {
          label: this.$t("pass.status"),
          sortable: true,
          slot: "status",
          prop: "status",
        },
        {
          label: this.$t("operation"),
          prop: "",
          width: 300,
          slot: "operation",
        },
      ],
    };
  },
  computed: {},
  methods: {
    status(status) {
      switch (status) {
        case 0:
          return this.$t("pass.status0");
        case 1:
          return this.$t("pass.status1");
      }
    },
    statusTag(statusTag) {
      switch (statusTag) {
        case 0:
          return 'info';
        case 1:
          return 'success';
      }
    },
    async handleClick(type, data) {
      switch (type) {
        case "add":
          this.$router.push(`/webpage/pass/edit`);
          break;
        case "check":
          this.$router.push(`/webpage/pass/detail?id=${data.id}`);
          break;
        case "status":
          await passEdit({
            ...data,
            status: data.status === 0 ? 1 : 0,
          });
          this.get_list();
          break;
        case "edit":
          this.$router.push(`/webpage/pass/edit?id=${data.id}`);
          break;
      }
    },
    async del(id) {
      this.$confirm(this.$t('sureDelete'), this.$t('tips'), {
        confirmButtonText: this.$t('btnTips').submit,
        cancelButtonText: this.$t('btnTips').cancel,
        type: 'warning'
      }).then(async () => {
        await passDelete(id)
        this.get_list()
      }).catch({
      })
    },
  },
};
</script>
<style lang="scss" scoped>

</style>